import Link from 'next/link'
import { postResponsiveImages, userResponsiveImage } from '../common/Utils';

export const NewsletterCard = ({ newsletter }) => {
  return (
    <>
      <hr className="my-4" />
      <Link className="w-fit" href={newsletter.url}>
        <div className="flex flex-row py-10 px-2 rounded-xl hover:bg-gray-100">
          <div className="grow">
            <div className="flex flex-row items-center">
              <div className="block w-18 flex-none mr-1">
                {userResponsiveImage(
                  newsletter.user,
                  "object-cover bg-white h-16 w-16 rounded-full"
                )}
              </div>
              <div className="grow">
                <h2
                  className="text-base md:text-xl grow break-all"
                  href={newsletter.url}
                >
                  {newsletter.title}
                </h2>
                <div className="text-sm">{newsletter.user.displayName}</div>
              </div>
            </div>
            <div className="text-sm text-gray-500 mt-4 break-all">
              {newsletter.subtitle}
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default NewsletterCard;